import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/mdx-page.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Last updated: 27th March, 2020.`}</em></p>
    <p>{`This Privacy Policy applies to the website "serverlessfirst.com", which is owned and operated by Winter Wind Software Ltd. This document describes how we collect, use and share the personal information provided to us by our customers or website visitors. It also describes your choices regarding use, access and correction of your personal information.`}</p>
    <h2>{`Types of data we collect`}</h2>
    <h3>{`Website Analytics`}</h3>
    <p>{`We use `}<a parentName="p" {...{
        "href": "https://analytics.google.com/analytics/web/"
      }}>{`Google Analytics`}</a>{` to monitor the aggregated usage of our website, e.g. what pages were viewed and what sites referred visitors to our site. This data is completely anonymised, so no personal data is transmitted or stored.`}</p>
    <h3>{`Mailing List`}</h3>
    <p>{`As part of the registration process for our e-newsletter, we collect some personal information, specifically an email address and your name. We use this information for a couple of reasons: to tell you about stuff you’ve asked us to tell you about; to contact you if we need to obtain or provide additional information. We don't rent or trade email lists with other organisations and businesses.
We use a third-party provider, `}<a parentName="p" {...{
        "href": "https://convertkit.com/"
      }}>{`ConvertKit`}</a>{`, to deliver our newsletter. We gather statistics around email opening and clicks using industry standard technologies to help us monitor and improve our e-newsletter. For more information, please see ConvertKit’s privacy notice. You can unsubscribe to general mailings at any time of the day or night by clicking the unsubscribe link at the bottom of any of our emails or by emailing `}<strong parentName="p"><a parentName="strong" {...{
          "href": "mailto:info@winterwindsoftware.com"
        }}>{`info@winterwindsoftware.com`}</a></strong>{`.`}</p>
    <h2>{`Access to your personal information`}</h2>
    <p>{`You are entitled to view, amend, or delete the personal information that we hold about you. Email your request to us at `}<strong parentName="p"><a parentName="strong" {...{
          "href": "mailto:info@winterwindsoftware.com"
        }}>{`info@winterwindsoftware.com`}</a></strong>{`.`}</p>
    <h2>{`Changes to our Privacy Policy`}</h2>
    <p>{`We will review this Privacy Policy periodically and if we decide to change it, we will post those changes on this page and update the Privacy Policy modification date above. We encourage you to periodically review this page for the latest information on our privacy practices.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      